.about-flex-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
}

.about-text {
    align-self: center;
    padding: 3rem;
    min-width: 18rem;
    text-align: justify;
    text-justify: inter-word;
}
@media (max-width: 975px) {
    .about-flex-container {
        margin-top: 1rem;
        flex-direction: column;
    }

    .about-text {
        align-self: center;
        padding: 1rem 0 0;
        min-width: 18rem;
        text-align: justify;
        text-justify: inter-word;
    }
}

.about-wrapper {
    padding-top: 3em;
    background: #d6ccca;
}