#kontakt {
    margin-top: 3rem;
    margin-bottom: 5rem;
}

.contact-flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
}
.contact-text {
    padding-top: 1rem;
}
.contact-text-left {
    max-width: 45rem;
    min-width: 30rem;
    text-align: justify;
    text-justify: inter-word;
}

.contact-card {
    max-width: 40rem;
    min-width: 30rem;
}
@media (max-width: 975px) {
    .contact-card {
        max-width: 40rem;
        min-width: 0;
    }
}