.home-height {
    height: 40rem;
}
.home-wrapper {
    background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url("Dach.jpg");
    background-blend-mode: darken;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.home-h1 {
    padding-top: 8rem;
}
.home-text {
    padding-top: 3rem;
}