.services-text {
    padding-top: 1rem;
    width: fit-content;
    text-align: justify;
    text-justify: inter-word;
}
.services-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

#dienstleistungen {
    margin-top: 3rem;
}
.green-roof {
    height: 15rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("Gründach.jpg");
}
.roof-repair {
    height: 15rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("Dachschaden.jpg");
}
.services-card {
    max-width: 40rem;
    min-width: 30rem;
}
@media (max-width: 975px) {
    .services-card {
        max-width: 40rem;
        min-width: 0;
    }
}
.skylight {
    height: 15rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("Velux.jpg");
}

.pigot-defense {
    height: 15rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("TaubenAbwehr2.jpg");
}

.gutter-cleaning {
    height: 15rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("Regenrinne.jpg");
}

.maintenance {
    height: 15rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("Wartung.jpg");
}